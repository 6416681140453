import React from 'react';
import logo from './ezcash.svg';
import './App.css';

function App() {
	return (
		<div className="App">
			<header className="App-header">
				IronPigeon
			</header>
			<body>
				<div>
					<h2>Software</h2>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<img src={logo} className="App-logo" alt="eZcash logo" height="40pt" style={{ marginRight: '10px' }} />
						<p>
							<a href="https://blog.nerdbank.net/ezcash-app">eZcash</a> is a Zcash cryptocurrency app available for Windows, Mac, Linux, Android and iOS.
						</p>
					</div>
				</div>
				<div>
					<h2>Software Consulting Services</h2>
					<p>
						I offer private software consulting and development services for the many OSS projects I contribute to, other OSS software you care about, as well as your proprietary software.
					</p><p>
						I have over 30 years of experience in software development, including 18 years as a Microsoft software engineer (currently a Principle Software Engineer). Note that my employer does not endorse or support any of the freelancing work offered on this page, even if that freelancing support relates to Microsoft-owned software.
					</p><p>
						My expertise is strongest in C# libraries and desktop applications. I have some limited experience developing mobile applications as well. I also have some experience in rust, Typescript, PowerShell, and a handful of other languages.
					</p>
					<a href="https://blog.nerdbank.net/for-hire">
						Get contact info and pricing
					</a>
				</div>
			</body>
		</div>
	)
}

export default App
